import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Import services
import { SeatsServices } from "../../services/SeatsServices";

// Import styled components
import { Container, Text } from "./CanceledBuyingStyle";
import { ThreeDots } from "react-loader-spinner";

export const CanceledBuying = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(15);

  const deleteSeats = async (seatId: number) => {
    await SeatsServices.deleteSeats(seatId);
  };

  useEffect(() => {
    if (localStorage["buyingSeats"]) {
      for (const seatId of JSON.parse(localStorage["buyingSeats"])) {
        deleteSeats(seatId);
      }
    }
    localStorage.clear();
    setIsLoading(false);
    setTimeout(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        navigate("/");
      }
    }, 1000);
  }, [counter]);

  return (
    <>
      <Container>
        {isLoading ? (
          <>
            <ThreeDots color="#ffb800 " />
          </>
        ) : (
          <>
            <Text>Odustali se od kupnje ulaznica!</Text>
            <Text>
              Pričekajte {counter} sekundi ili kliknite na gumb ispod kako bi se
              vratili na stranicu kina.
            </Text>
            <Link to="/">POVRATAK</Link>
          </>
        )}
      </Container>
    </>
  );
};

export default CanceledBuying;

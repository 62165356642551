import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

// Import images
import FreeSeats from "../../assets/img/free-seats.png";
import SelectedSeats from "../../assets/img/selected-seats.png";
import TakenSeats from "../../assets/img/taken-seats.png";

// Import styled components
import {
  Auditorium,
  Canvas,
  SeatsContainer,
  Seats,
  Path,
  Row,
  Column,
} from "./CinemaStyle";
import { ProjectionsServices } from "../../services/ProjectionsServices";
import { OccupiedSeats } from "../../models/OccupiedSeats";

const Cinema = ({
  projectionId,
  setSelectedSeats,
  selectedSeats,
  seatSeatsStatus,
  seatsStatus,
  seats,
}: Props) => {
  useEffect(() => {
    ProjectionsServices.getAllOccupiedSeatsOnProjection(projectionId).then(
      (occupiedSeats: OccupiedSeats[]) => {
        for (const seat of occupiedSeats) {
          seatsStatus[seat.row][seat.column - 1] = TakenSeats;
        }
        seatSeatsStatus({ ...seatsStatus });
      }
    );
  }, []);

  const printCinema = () => {
    let cinema = [];

    for (let r = 1; r <= seats.length; r++) {
      let row = [];

      row.push(
        <>
          <Row>{r}</Row>
        </>
      );

      for (const [key, seat] of seats[r - 1].entries()) {
        if (seat === 0) {
          row.push(
            <>
              <Path />
            </>
          );
          continue;
        }
        row.push(
          <>
            <Seats
              seatsStatus={seatsStatus[r][key]}
              onClick={() => {
                let clickedSeats = seatsStatus[r][key];
                if (clickedSeats === TakenSeats) return;
                if (clickedSeats === FreeSeats) {
                  seatsStatus[r][key] = SelectedSeats;
                  setSelectedSeats([...selectedSeats, seat]);
                } else if (clickedSeats === SelectedSeats) {
                  seatsStatus[r][key] = FreeSeats;
                  setSelectedSeats(
                    selectedSeats.filter((item) => item !== seat)
                  );
                }
                seatSeatsStatus({ ...seatsStatus });
              }}
            />
          </>
        );
      }

      cinema.push(row);
    }

    let columns = [];
    for (let column = 0; column <= 13; column++) {
      if (column === 0) {
        columns.push(
          <>
            <Path />
          </>
        );
        continue;
      }
      columns.push(
        <>
          <Column>{column}</Column>
        </>
      );
    }

    cinema.push(columns);

    return cinema;
  };

  return (
    <>
      <Auditorium>
        <Canvas>Platno</Canvas>
        <SeatsContainer>{printCinema()}</SeatsContainer>
      </Auditorium>
    </>
  );
};

export default Cinema;

type Props = {
  setSelectedSeats: Dispatch<SetStateAction<number[]>>;
  selectedSeats: number[];
  seatSeatsStatus: Dispatch<
    SetStateAction<{
      [key: number]: string[];
    }>
  >;
  seatsStatus: {
    [key: number]: string[];
  };
  seats: number[][];
  projectionId: number;
};

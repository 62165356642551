import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Import styled components
import { Container, Text } from "./SucceedBuyingStyle";
import { ThreeDots } from "react-loader-spinner";
import { ProjectionsServices } from "../../services/ProjectionsServices";

const SucceedBuying = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(15);

  const succeedBuy = async (
    projectionId: number,
    projectionPrice: number,
    buyingCode: string
  ) => {
    await ProjectionsServices.succeedBuy(
      projectionId,
      projectionPrice,
      buyingCode
    )
      .then(() => {
        setTimeout(() => {
          localStorage.clear();
          setIsLoading(false);
        }, 2000);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };

  useEffect(() => {
    if (isLoading === true) {
      if (localStorage["buyingCode"]) {
        const buyingCode: string = localStorage["buyingCode"];
        const projectionId: number = localStorage["projection"];
        const projectionPrice: number = localStorage["price"];
        succeedBuy(projectionId, projectionPrice, buyingCode);
      } else {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        clearInterval(interval);
        navigate("/");
      }
    }, 1000);
  }, [counter]);

  return (
    <>
      <>
        <Container>
          {isLoading ? (
            <>
              <ThreeDots color="#ffb800 " />
              <Text>
                Vaša kupnja se obrađuje. Molimo pričekajte dok obrada ne završi!
              </Text>
            </>
          ) : (
            <>
              {isError ? (
                <>
                  <Text>
                    Došlo je do pogreške prilikom kupnje karata! Pošaljite nam
                    svoj problem na kino@pou-krizevci.hr.
                  </Text>
                  <Text>
                    Pričekajte {counter} sekundi ili kliknite na gumb ispod kako
                    bi se vratili na stranicu kina.
                  </Text>
                  <Link to="/">POVRATAK</Link>
                </>
              ) : (
                <>
                  <Text>
                    Uspješno ste kupili ulaznice! Provjerite da li se u vašem
                    e-mail pretinacu nalazi kod za ulaznice!
                  </Text>
                  <Text>
                    Pričekajte {counter} sekundi ili kliknite na gumb ispod kako
                    bi se vratili na stranicu kina.
                  </Text>
                  <Link to="/">POVRATAK</Link>
                </>
              )}
            </>
          )}
        </Container>
      </>
    </>
  );
};

export default SucceedBuying;

export class SeatsServices {
  private static apiUrl: string = "https://shark-app-xoiff.ondigitalocean.app";

  public static deleteSeats(seatsId: number) {
    return fetch(`${this.apiUrl}/occupied-seats/delete/${seatsId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .catch(() => {});
  }
}

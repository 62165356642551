import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import hmacSHA256 from "crypto-js/hmac-sha256";
import openSocket from "socket.io-client";

// Import images
import FreeSeats from "../../assets/img/free-seats.png";
import SelectedSeats from "../../assets/img/selected-seats.png";
import TakenSeats from "../../assets/img/taken-seats.png";

// Import environment variables
import {
  corvusPaySignKey,
  corvusPayStoreId,
  corvusPayValuta,
  corvusPayVersion,
} from "../../utils/Environment";

// Import styled components
import {
  HeadliningContainer,
  FilmContainer,
  FilmHeadlining,
  Date,
  Time,
  TicketsNumber,
  BuyButton,
  Container,
  PriceContainer,
  PriceText,
} from "./TicketsStyle";

// Import components
import Cinema from "../Cinema/Cinema";
import { Projections } from "../../models/Projections";
import { ProjectionsServices } from "../../services/ProjectionsServices";
import SellingPopup from "../SellingPopup/SellingPopup";

const Tickets = () => {
  const { projectionId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [selectedSeats, setSelectedSeats] = useState<number[]>([]);
  const [projection, setProjection] = useState<Projections>();
  const seats: number[][] = [
    [1, 9, 8, 7, 6, 5, 4, 3, 2, 0, 0, 0, 0],
    [37, 38, 39, 40, 41, 42, 43, 44, 45, 0, 0, 0, 0],
    [28, 29, 30, 31, 32, 33, 34, 35, 36, 0, 0, 0, 0],
    [19, 20, 21, 22, 23, 24, 25, 26, 27, 0, 0, 0, 0],
    [10, 11, 12, 13, 14, 15, 16, 17, 18, 0, 0, 0, 0],
    [46, 47, 48, 49, 50, 51, 52, 53, 54, 0, 0, 55, 56],
    [57, 58, 59, 60, 61, 62, 63, 64, 65, 0, 0, 66, 67],
    [68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 80, 79],
  ];

  const [seatsStatus, seatSeatsStatus] = useState<{
    [key: number]: string[];
  }>({
    1: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
    ],
    2: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
    ],
    3: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
    ],
    4: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
    ],
    5: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
    ],
    6: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      undefined,
      undefined,
      FreeSeats,
      FreeSeats,
    ],
    7: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      undefined,
      undefined,
      FreeSeats,
      FreeSeats,
    ],
    8: [
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
      FreeSeats,
    ],
  });

  const socket = openSocket("https://shark-app-xoiff.ondigitalocean.app");

  socket.on("posts", (data) => {
    if (data.action === "create") {
      let seatsArray: number[] = selectedSeats;
      for (const seat of data.newOccupiedSeat) {
        if (seat.projection == projectionId) {
          let row: number = 0;
          let columns: number = 0;
          for (const [index, seatId] of seats.entries()) {
            const column: number = seatId.findIndex(
              (item) => item === seat.seats
            );
            if (column !== -1) {
              columns = column;
              row = index + 1;
              seatsArray.filter((seats) => seats !== seat.id);
            }
          }
          seatsStatus[row][columns] = TakenSeats;
          seatSeatsStatus({ ...seatsStatus });
        }
      }
      setSelectedSeats(seatsArray);
    }
  });

  socket.on("delete", (data) => {
    if (data.action === "delete") {
      if (data.deletedSeats.projection == projectionId) {
        let row: number = 0;
        let columns: number = 0;
        for (const [index, seatId] of seats.entries()) {
          const column: number = seatId.findIndex(
            (item) => item === data.deletedSeats.seats
          );
          if (column !== -1) {
            columns = column;
            row = index + 1;
          }
        }
        seatsStatus[row][columns] = FreeSeats;
        seatSeatsStatus({ ...seatsStatus });
      }
    }
  });

  useEffect(() => {
    localStorage.clear();
    ProjectionsServices.getProjectionById(Number(projectionId!)).then(
      (projection: Projections) => {
        setProjection(projection);
      }
    );
  }, []);

  useEffect(() => {
    console.log(selectedSeats);
  }, [selectedSeats]);

  const handleClick = async () => {
    if (name === "") {
      setError(true);
      return;
    }
    if (lastName === "") {
      setError(true);
      return;
    }
    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setError(true);
      return;
    }
    setError(false);

    // add seats to database before go to payment
    let response = await ProjectionsServices.addOccupiedSeatsToProjection(
      projection!,
      name,
      lastName,
      email,
      selectedSeats
    );

    if (response.status === 200) {
      if (!projection?.isFree) {
        const jsonResponse = await response.json();
        const buyingCode: string = jsonResponse.buyingCode;
        // set local storage information for resolve buying tickets
        localStorage.setItem("buyingCode", buyingCode);
        localStorage.setItem("projection", projectionId!);
        localStorage.setItem("price", projection?.price!.toString()!);

        const form = document.createElement("form");
        form.target = "_self";
        // Production
        form.action = "https://wallet.corvuspay.com/checkout/";
        // Testing
        //form.action = "https://test-wallet.corvuspay.com/checkout/";
        form.method = "POST";
        const input = document.createElement("input");
        input.name = "order_number";
        // create generation web buying code on API
        input.value = buyingCode;
        form.appendChild(input);
        const input2 = document.createElement("input");
        input2.name = "signature";
        input2.value = calcSignature(buyingCode);
        form.appendChild(input2);
        const input3 = document.createElement("input");
        input3.name = "version";
        input3.value = corvusPayVersion;
        form.appendChild(input3);
        const input4 = document.createElement("input");
        input4.name = "store_id";
        input4.value = corvusPayStoreId.toString();
        form.appendChild(input4);
        const input5 = document.createElement("input");
        input5.name = "language";
        input5.value = "hr";
        form.appendChild(input5);
        const input6 = document.createElement("input");
        input6.name = "currency";
        input6.value = corvusPayValuta;
        form.appendChild(input6);
        const input7 = document.createElement("input");
        input7.name = "amount";
        input7.value = (selectedSeats.length * projection?.price!)
          .toFixed(2)
          .toString();
        form.appendChild(input7);
        const input8 = document.createElement("input");
        input8.name = "cart";
        input8.value =
          selectedSeats.length +
          "x " +
          projection?.moviename +
          ", " +
          projection?.date
            .toString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join(".") +
          " " +
          projection?.time.toString().substring(0, 5);
        form.appendChild(input8);
        const input9 = document.createElement("input");
        input9.name = "require_complete";
        input9.value = "false";
        form.appendChild(input9);

        document.body.appendChild(form);
        form.submit();
      } else {
        // redirect to succeed buy
        navigate("/uspijeh");
      }
    }
  };

  const calcSignature = (code: string) => {
    let corvusPayObj = {
      version: corvusPayVersion,
      store_id: corvusPayStoreId,
      order_number: code,
      amount: (selectedSeats.length * projection?.price!).toFixed(2),
      currency: corvusPayValuta,
      cart:
        selectedSeats.length +
        "x " +
        projection?.moviename +
        ", " +
        projection?.date
          .toString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join(".") +
        " " +
        projection?.time.toString().substring(0, 5),
      require_complete: false,
      language: "hr",
    };

    let message = "";
    let parameterArray = [];
    let counter = 0;

    for (let key in corvusPayObj) {
      parameterArray[counter++] =
        key + "" + corvusPayObj[key as keyof typeof corvusPayObj];
    }

    parameterArray.sort();

    parameterArray.forEach((element) => {
      message += element;
    });

    let signature = hmacSHA256(message, corvusPaySignKey);

    return "" + signature;
  };

  return (
    <>
      {projection === undefined ? (
        <></>
      ) : (
        <>
          <HeadliningContainer>
            <FilmContainer>
              <FilmHeadlining>{projection?.moviename}</FilmHeadlining>
              <Date>
                Datum:{" "}
                {projection?.date
                  .toString()
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join(".")}
                {"."}
              </Date>
              <Time>
                Vrijeme: {projection?.time.toString().substring(0, 5)}h
              </Time>
            </FilmContainer>
            <TicketsNumber>
              Broj Ulaznica <hr></hr> <b>{selectedSeats.length}</b>
            </TicketsNumber>
            <BuyButton
              onClick={() => {
                if (selectedSeats.length !== 0) {
                  setOpenPopup(true);
                }
              }}
            >
              Kupi
            </BuyButton>
          </HeadliningContainer>
          <Container>Odabir ulaznica</Container>
          <Cinema
            projectionId={Number(projectionId!)}
            setSelectedSeats={setSelectedSeats}
            selectedSeats={selectedSeats}
            seatSeatsStatus={seatSeatsStatus}
            seatsStatus={seatsStatus}
            seats={seats}
          />
          <PriceContainer>
            <PriceText>Broj ulaznica: {selectedSeats.length} </PriceText>
            <PriceText>
              Ukupna cijena: {selectedSeats.length * projection?.price!} €
            </PriceText>
          </PriceContainer>
          <SellingPopup
            projection={projection!}
            numberOfTickets={selectedSeats.length}
            setName={setName}
            setLastName={setLastName}
            setEmail={setEmail}
            handleClick={handleClick}
            error={error}
            isOpen={openPopup}
            setOpenPopup={setOpenPopup}
          />
        </>
      )}
    </>
  );
};

export default Tickets;
